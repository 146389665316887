<template>
  <div class="review">
    <template v-if="showDetail">
      <div class="detail">
        <div class="head" @click="goList"><van-icon name="arrow-left" />返回列表</div>
        <div class="content">
          <div class="title">{{ activeItem.name }}</div>
          <div class="cont" v-html="activeItem.content" />
        </div>
      </div>
    </template>
    <template v-else>
      <div v-for="item in list" :key="item.id" class="item" @click="goDetail(item)">
        <div class="title">{{ item.name }}</div>
        <div class="des">{{ item.desc }}</div>
        <div class="img"><img :src="item.img_url"></div>
      </div>
    </template>
  </div>
</template>

<script>
import { getList } from '@/api/index'
export default {
  props: {
    infos: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      list: [],
      showDetail: false,
      activeItem: {}
    }
  },
  mounted() {
    this.getReviewList()
  },
  methods: {
    goDetail(item) {
      if (item.link) {
        window.location.href = item.link
      } else {
        this.activeItem = item
        this.showDetail = true
      }
    },
    goList() {
      this.activeItem = {}
      this.showDetail = false
    },
    async getReviewList() {
      const res = await getList('product_video', { product_id: this.infos.id, limit: 999 })
      this.list = res.data
    }
  }
}
</script>

<style lang="scss" scoped>
.review {
  padding: 15px;
  .item {
    padding: 10px;
    margin-bottom: 10px;
    background: #1A1A1A;
    .title {
      font-size: 14px;
    }
    .des {
      padding: 10px 0;
      font-size: 10px;
      color: #999999;
      line-height: 15px;
    }
    img {
      width: 100%;
    }
  }
  .detail {
    .head {
      padding: 10px 0;
      font-size: 14px;
    }
    .content {
      padding: 10px;
      background: #1A1A1A;
      .title {
        padding-bottom: 10px;
        font-size: 14px;
        text-align: center;
      }
    }
  }
}
</style>
