<template>
  <div class="detail" v-html="infos.technical_support_content" />
</template>

<script>
export default {
  props: {
    infos: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  padding: 5px;
}
</style>
