<template>
  <div class="product-detail">
    <div class="menu-list">
      <van-tabs v-model="activeTab" background="#1A1A1A" color="#FF0000" title-inactive-color="#fff" title-active-color="#fff" :before-change="beforeChange">
        <van-tab v-for="item in menuList" :key="item.id" :title="item.title">
          <components :is="item.component" :infos="infos" />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import Detail from './components/Detail'
import Video from './components/Video'
import Technical from './components/Technical'
import BuyPro from './components/BuyPro'
import Photo from './components/Photo'
import Review from './components/Review'
import Spec from './components/Spec'
import { getInfo } from '@/api/index'
export default {
  components: { Detail, Video, Technical, BuyPro, Photo, Review, Spec },
  data() {
    return {
      pageId: null,
      activeTab: 0,
      infos: {},
      menuList: [
        { id: 1, title: '产品详情', component: 'Detail' },
        { id: 1, title: '规格参数', component: 'Spec' },
        { id: 2, title: '视频介绍', component: 'Video' },
        { id: 3, title: '评测关联', component: 'Review' },
        { id: 4, title: '图赏', component: 'Photo' },
        { id: 5, title: '技术支持', component: 'Technical' },
        { id: 6, title: '购买产品', component: 'BuyPro' }
      ]
    }
  },
  mounted() {
    this.pageId = this.$route.query.pageId
    if (this.$route.query.activeTab) {
      this.activeTab = 4
    }
    this.getProductDetail()
  },
  methods: {
    async getProductDetail() {
      const res = await getInfo('product', { id: this.pageId })
      this.infos = res.info
      console.log(res, 11)
    },
    beforeChange(index) {
      const item = this.menuList[index]
      if (item.id === 5 && this.infos.technical_support_link) {
        window.location.href = this.infos.technical_support_link
        return false
      }
      if (item.id === 6 && this.infos.buy_link) {
        window.location.href = this.infos.buy_link
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
