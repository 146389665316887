<template>
  <div class="detail" v-html="infos.content" />
</template>

<script>
export default {
  props: {
    infos: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.detail::v-deep {
  padding: 5px;
  img {
    height: auto !important;
  }
}
</style>
